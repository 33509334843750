import React, { FunctionComponent, Fragment, useRef, useEffect } from "react"
import { FormattedMessage } from "react-intl"
import { Link } from "gatsby"
import { Store } from "../../../../core/constants"
import { useOnScreen } from "../../../../hooks/use-on-screen"
import { ProductImage } from "../../../../components/product-image/product-image"
import { ShareAndLikeButton } from "../../../../components/share-and-like-button/share-and-like-button"
import { SpinnerButton } from "../../../../components/button/spinner-button"
import { ProductDetails, ProductVariant } from "../../store-types"
import { addDetailView } from "../../../../hooks/useRecombee"
import { useRecentlyViewed } from "../../../../contexts/recentlyViewedContext"
import {
  Availability,
  AvailabilityMessageMap
} from "../../product-availability-source"
import { getCustomAttributes } from "../../../../utils/shopify-buy"
import { useShopifyProduct } from "../../../../hooks/useShopifyProduct"
import { formatColorLanguage, formatVariantTitle } from "../../store-helpers"
import { Teacher } from "../../../../types/teacher"
import { ProductAuthors } from "./product-authors"
import { Prices } from "./prices"
import { ProductMiniHeader } from "./product-mini-header"
import styles from "./product-header.module.scss"
import { useNewCart } from "@contexts/newCartContext"
import { useAuth } from "@contexts/authContext"
import { Skeleton } from "@components/skeleton/skeleton"
import { Markdown } from "@components/markdown/markdown"

export type ProductHeaderLocation =
  | "homepage"
  | "store-main-page"
  | "product-details"
  | "coram-deo"

export interface ProductHeaderProps {
  product: ProductDetails
  variant: ProductVariant
  headerLocation?: ProductHeaderLocation
  headline?: string
  subhead?: string
  buttonText?: string
  showSecondaryLink?: boolean
  imageLink?: boolean
  secondaryLinkText?: string
}

const reactMarkdownProps = {
  disallowedElements: ["p", "h1", "h2", "h3", "h4", "h5", "h6"],
  unwrapDisallowed: true
}

export const ProductHeader: FunctionComponent<ProductHeaderProps> = ({
  product,
  variant,
  headerLocation,
  headline,
  imageLink = true,
  subhead,
  buttonText,
  showSecondaryLink,
  secondaryLinkText
}) => {
  const currentHandle = variant.handle
  const imageRef = useRef<HTMLImageElement>(null)
  const isMiniheaderVisible = useOnScreen(imageRef, styles.menuOffset, true)
  const cartRef = useRef<HTMLDivElement>(null)
  const [{ context, value: cartStateValue }, sendCartEvent] = useNewCart()
  const { isLoading, error, data } = useShopifyProduct(currentHandle)
  const [rvState, sendRVEvent] = useRecentlyViewed()
  const [authState] = useAuth()

  const { userDetails } = authState.context
  const userId = userDetails?.sub
  const { itemsBeingAdded } = context

  const shopProduct = data?.product
  const defaultVariant = shopProduct?.variants?.[0]
  const availability = data?.availability ?? Availability.unknown
  const price = data?.price || ""
  const compareAtPrice = data?.compareAtPrice || ""

  const itemIsBeingAdded = itemsBeingAdded.includes(
    defaultVariant?.storefrontId || ""
  )

  const isDetailPage = headerLocation === "product-details"
  const productFormat = variant.format || formatVariantTitle(variant.title)

  const images = product.activeVariant.images
  const imageUrl =
    images.perspective || images.main || variant.image || product.image

  const addVariantToCart = async () => {
    const customAttributes = getCustomAttributes(
      { ...shopProduct, ...product, availability },
      {
        ...variant,
        quantityAvailable: defaultVariant?.quantityAvailable,
        requiresShipping: defaultVariant?.requiresShipping
      }
    )

    sendCartEvent("ADD_LINE_ITEM", {
      data: {
        variantId: defaultVariant?.storefrontId || "",
        customAttributes
      }
    })
  }

  useEffect(() => {
    if (userId && isDetailPage) {
      const callRecom = async () =>
        await addDetailView({ userId, itemId: variant.sku })

      callRecom()
    } else if (isDetailPage) {
      const found =
        rvState.context.products.findIndex(
          ({ handle }) => handle === currentHandle
        ) >= 0

      if (!found) {
        sendRVEvent("ADD_PRODUCTS", {
          products: [variant]
        })
      }
    }
  }, [currentHandle, rvState, sendRVEvent, variant, isDetailPage, userId])

  const preorderMessage = shopProduct?.publicationDate
    ? "Available starting " + shopProduct.publicationDate.toLocaleDateString()
    : "Coming soon"

  const backorderMessage = shopProduct?.backorderCustomDate
    ? "This item is on backorder and is scheduled to ship by " +
      shopProduct.backorderCustomDate.toLocaleDateString() +
      ". We will send you an email notification once your item(s) ship."
    : shopProduct?.backorderCustomMessage?.value ||
      "This item is on backorder. We will send you an email notification once your item(s) ship."

  const productLink = `${Store}/${product.activeVariant.handle}`

  const PurchaseSection = () => {
    if (isLoading) {
      return (
        <>
          <div className={styles.prices}>
            <Skeleton />
          </div>
          <SpinnerButton
            size="md"
            variant="primary"
            className={styles.addToCartButton}
            disabled
            processing
          />
          <div className={styles.backOrderMsg}>
            <Skeleton />
          </div>
        </>
      )
    }

    if (error) {
      return null
    }

    const addToCartLabelId = AvailabilityMessageMap[availability]

    const canPurchase = [
      Availability.available,
      Availability.preorder,
      Availability.backorder
    ].includes(availability)

    const secondaryLink = (
      <Link to={productLink} className={styles.prices}>
        {secondaryLinkText || "Learn More"}
      </Link>
    )

    if (canPurchase) {
      return (
        <>
          {showSecondaryLink ? (
            secondaryLink
          ) : (
            <Prices
              price={price}
              compareAtPrice={compareAtPrice}
              className={styles.prices}
            />
          )}
          <SpinnerButton
            size="md"
            variant="primary"
            className={styles.addToCartButton}
            processing={itemIsBeingAdded}
            onClick={addVariantToCart}
          >
            <span>
              {buttonText ||
                (addToCartLabelId && (
                  <FormattedMessage id={addToCartLabelId} />
                ))}
            </span>
          </SpinnerButton>
          {availability === Availability.backorder && (
            <div className={styles.backOrderMsg}>{backorderMessage}</div>
          )}
          {availability === Availability.preorder && (
            <div className={styles.backOrderMsg}>{preorderMessage}</div>
          )}
        </>
      )
    }

    if (showSecondaryLink) return secondaryLink

    return (
      <div className={styles.availabilityMessage}>
        {availability === Availability.notAvailableYet ? (
          preorderMessage
        ) : (
          <FormattedMessage id={AvailabilityMessageMap[availability]} />
        )}
      </div>
    )
  }

  const language =
    variant.language || shopProduct?.language?.value || product.language
  const color = variant.color || shopProduct?.color?.value

  return (
    <Fragment>
      {isDetailPage && (
        <ProductMiniHeader
          onButtonClick={addVariantToCart}
          processing={cartStateValue !== "idle" ? itemIsBeingAdded : false}
          product={product}
          variant={variant}
          visible={!isMiniheaderVisible}
          headerLocation={headerLocation}
          availability={availability}
          price={price}
          compareAtPrice={compareAtPrice}
          language={language || undefined}
          color={color || undefined}
        />
      )}
      <header className={styles.productHeader}>
        <div className={styles.image} ref={imageRef}>
          {imageUrl ? (
            <div className={styles.productImage}>
              <ProductImage
                alt={product.title}
                src={imageUrl}
                url={imageLink ? productLink : undefined}
                size={undefined}
              />
            </div>
          ) : (
            <div className={styles.imagePlaceholder} />
          )}
        </div>
        <div className={styles.info}>
          <div className={styles.variant}>
            {formatColorLanguage(productFormat, color, language)}
          </div>
          {isDetailPage ? (
            <Fragment>
              <h1 className={styles.title}>{product.title}</h1>
              {product.subtitle && (
                <h1 className={styles.subtitle}>{product.subtitle}</h1>
              )}
            </Fragment>
          ) : (
            <Fragment>
              <h2 className={styles.titleLink}>
                {headline ? (
                  <Link to={productLink}>
                    <Markdown content={headline} {...reactMarkdownProps} />
                  </Link>
                ) : (
                  <Link to={productLink}>{product.title}</Link>
                )}
              </h2>
              {subhead ? (
                <h2 className={styles.subtitle}>
                  <Markdown content={subhead} {...reactMarkdownProps} />
                </h2>
              ) : (
                <h2 className={styles.subtitle}>{product.subtitle}</h2>
              )}
            </Fragment>
          )}

          <div className={styles.metaWrapper}>
            <div className={styles.meta}>
              {isLoading ? (
                <Skeleton className={styles.authorsSkeleton} />
              ) : (
                <>
                  <ProductAuthors authors={product.teachers as Teacher[]} />
                  {variant.pageCount && (
                    <div className={styles.pageCount}>
                      {variant.pageCount} Pages
                    </div>
                  )}
                </>
              )}
            </div>
            {isDetailPage && !isLoading && (
              <ShareAndLikeButton
                shareUrl={`${Store}/${variant.handle}`}
                id={variant.id}
                contentType="product"
              />
            )}
          </div>
          <div className={styles.cartWrapper} ref={cartRef}>
            <PurchaseSection />
          </div>
        </div>
      </header>
    </Fragment>
  )
}
